import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<any>;
  uid: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth, 
    private db: AngularFirestore, 
    private navCtrl: NavController
    ) {



    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc(`customers/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )

    this.uid = this.afAuth.authState.pipe(
      switchMap((authState) => {
          if (!authState) {
              return null;
          } else {
              return authState.uid;
          }
      })
    );

  }

  getUser(): firebase.User {
    return firebase.auth().currentUser; 
  }

  getUser$(): Observable<firebase.User> { 
    return of(this.getUser());
  }

  signIn(credentials): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
      switchMap(user => {
        console.log('real user: ', user);
        if (user) {
          return this.db.doc(`customers/${user.user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  async signUp(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      console.log('after register: ', data);
      return this.db.doc(`customers/${data.user.uid}`).set({
        name: credentials.name.trim() + ' ' + credentials.lastname.trim(),
        email: credentials.email,
        phone: credentials.phone,
        role: credentials.role,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  async signUpZip(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      console.log('after register: ', data);
      return this.db.doc(`customers/${data.user.uid}`).set({
        name: credentials.name.trim() + ' ' + credentials.lastname.trim(),
        email: credentials.email,
        phone: credentials.phone,
        postalcode: credentials.zip,
        role: credentials.role,
        keep: credentials.keep,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  async signUpAddress(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      console.log('after register: ', data);
      return this.db.doc(`customers/${data.user.uid}`).set({
        name: credentials.name,
        email: credentials.email,
        phone: credentials.phone,
        address: credentials.address,
        city: credentials.city,
        province: credentials.province,
        postalcode: credentials.postalcode,
        country: credentials.country,
        role: credentials.role,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  signOutAuction() {
    console.log("Auth Signout");
    this.afAuth.signOut().then(() => {
      console.log("Go to Login");
      this.navCtrl.navigateRoot('auction/login');
    });
  }

  signOutWholesale() {
    console.log("Wholesale Signout");
    this.afAuth.signOut().then(() => {
      console.log("Go to Login");
      this.navCtrl.navigateRoot('wholesale/login');
    });
  }


  signOutNew(type: string) {
    console.log("Auth Signout");
    this.afAuth.signOut().then(() => {
      console.log("Go to Login");
      this.navCtrl.navigateRoot(`${type}/login`);
    });
  }

  resetPassword(email: string): Promise<void> {
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back to this page.
      url: 'https://fundables.app/auction/login',
      handleCodeInApp: false
    };
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  }

  resetPasswordNew(email: string, type: string): Promise<void> {
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back to this page.
      url: `https://fundables.app/${type}/login`,
      handleCodeInApp: false
    };
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  }

}

