import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RoleGuard } from './guards/role.guard';
import { AutomaticLoginGuard } from './guards/automatic-login.guard';

const redirectUnauthorizedToAuction = () => redirectUnauthorizedTo(['auction/login']);
//const redirectUnauthorizedToAuction = (next) => redirectUnauthorizedTo([`auction/login/${next.params.id}`]);
const redirectUnauthorizedToWholesale = () => redirectUnauthorizedTo(['wholesale/login']);

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { 
    path: 'home', 
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), 
  },
  { 
    path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  { 
    path: 'profile', 
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard] 
  },
  { 
    path: 'reset-password', 
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) 
  },
  { 
    path: 'signup', 
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule) 
  },
  { 
    path: 'campaign-create', 
    loadChildren: () => import('./pages/campaign-create/campaign-create.module').then(m => m.CampaignCreatePageModule) ,
    canActivate: [AuthGuard] 
  },
  { 
    path: 'campaign-detail/:id', 
    loadChildren: () => import('./pages/campaign-detail/campaign-detail.module').then(m => m.CampaignDetailPageModule),
    canActivate: [AuthGuard] 
  },
  { 
    path: 'campaign-list', 
    loadChildren: () => import('./pages/campaign-list/campaign-list.module').then(m => m.CampaignListPageModule),
    canActivate: [AuthGuard] 
  }
    ,
  { 
    path: 'product-create/:id', 
    loadChildren: () => import('./pages/product-create/product-create.module').then(m => m.ProductCreatePageModule),
    canActivate: [AuthGuard] 
  },
  { 
    path: 'campaign-cart/:id', 
    loadChildren: () => import('./pages/campaign-cart/campaign-cart.module').then(m => m.CampaignCartPageModule),
  },
  { 
    path: 'campaign/:id', 
    loadChildren: () => import('./pages/campaign-cart/campaign-cart.module').then(m => m.CampaignCartPageModule),
  },
  { 
    path: 'product-cart/:id', 
    loadChildren: () => import('./pages/product-cart/product-cart.module').then(m => m.ProductCartPageModule) 
  },
  { 
    path: 'product-order/:id', 
    loadChildren: () => import('./pages/product-order/product-order.module').then(m => m.ProductOrderPageModule) 
  },
  { 
    path: 'checkout-cart', // /:id
    loadChildren: () => import('./pages/checkout-cart/checkout-cart.module').then(m => m.CheckoutCartPageModule) 
  },
  { 
    path: 'checkout-address',
    loadChildren: () => import('./pages/checkout-address/checkout-address.module').then(m => m.CheckoutAddressPageModule) 
  },
  { 
    path: 'checkout-review',
    loadChildren: () => import('./pages/checkout-review/checkout-review.module').then(m => m.CheckoutReviewPageModule) 
  },
  { 
    path: 'checkout-order', 
    loadChildren: () => import('./pages/checkout-order/checkout-order.module').then(m => m.CheckoutOrderPageModule) 
  },
  { 
    path: 'product-edit/:id', 
    loadChildren: () => import('./pages/product-edit/product-edit.module').then(m => m.ProductEditPageModule),
    canActivate: [AuthGuard] 
  },
  { 
    path: 'team-invite/:id', 
    loadChildren: () => import('./pages/team-invite/team-invite.module').then(m => m.TeamInvitePageModule),
    canActivate: [AuthGuard] 
  },
  { 
    path: 'campaign-edit', 
    loadChildren: () => import('./pages/campaign-edit/campaign-edit.module').then(m => m.CampaignEditPageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'campaign-search', 
    loadChildren: () => import('./pages/campaign-search/campaign-search.module').then(m => m.CampaignSearchPageModule) 
  },
  { 
    path: 'checkout-interac', 
    loadChildren: () => import('./pages/checkout-interac/checkout-interac.module').then(m => m.CheckoutInteracPageModule) 
  },
  { 
    path: 'campaign-report/:id', 
    loadChildren: () => import('./pages/campaign-report/campaign-report.module').then(m => m.CampaignReportPageModule),
    canActivate: [AuthGuard] 
  },
  { 
    path: 'cert/:id', 
    loadChildren: () => import('./pages/cert/cert.module').then(m => m.CertPageModule) 
  },
  { 
    path: 'interac-code/:id', 
    loadChildren: () => import('./pages/interac-code/interac-code.module').then(m => m.InteracCodePageModule) 
  },
  { 
    path: 'order-email', 
    loadChildren: () => import('./pages/order-email/order-email.module').then(m => m.OrderEmailPageModule) 
  },
  {
    path: 'auction/login',
    loadChildren: () => import('./pages/auction/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AutomaticLoginGuard]
  },
  {
    path: 'auction/auction-detail/:id',
    loadChildren: () => import('./pages/auction/auction-detail/auction-detail.module').then( m => m.AuctionDetailPageModule)
  },
  {
    path: 'auction/item-create/:id',
    loadChildren: () => import('./pages/auction/item-create/item-create.module').then( m => m.ItemCreatePageModule)
  },
  {
    path: 'auction/item-edit/:id',
    loadChildren: () => import('./pages/auction/item-edit/item-edit.module').then( m => m.ItemEditPageModule)
  },
  {
    path: 'auction/id/:id',
    loadChildren: () => import('./pages/auction/auction/auction.module').then( m => m.AuctionPageModule)
  },
  {
    path: 'auction/item-detail/:id/:id2',
    loadChildren: () => import('./pages/auction/item-detail/item-detail.module').then( m => m.ItemDetailPageModule)
  },
  {
    path: 'auction/checkout-review/:id',
    loadChildren: () => import('./pages/auction/checkout-review/checkout-review.module').then( m => m.CheckoutReviewPageModule)
  },
  {
    path: 'auction',
    canActivate: [AngularFireAuthGuard, RoleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToAuction,
      role: 'auction'
    },
    children: [
      {
        path: 'checkout-order',
        loadChildren: () => import('./pages/auction/checkout-order/checkout-order.module').then( m => m.CheckoutOrderPageModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/auction/auction-search/auction-search.module').then( m => m.AuctionSearchPageModule)
      },
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'product-detail/:id',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'product-direct/:id/:id2',
    loadChildren: () => import('./pages/product-direct/product-direct.module').then( m => m.ProductDirectPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auction/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'wholesale/login',
    loadChildren: () => import('./pages/wholesale/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AutomaticLoginGuard]
  },
  {
    path: 'wholesale/wholesale-detail/:id',
    loadChildren: () => import('./pages/wholesale/wholesale-detail/wholesale-detail.module').then( m => m.WholesaleDetailPageModule)
  },
  {
    path: 'wholesale/item-create/:id',
    loadChildren: () => import('./pages/wholesale/item-create/item-create.module').then( m => m.ItemCreatePageModule)
  },
  {
    path: 'wholesale/item-edit/:id',
    loadChildren: () => import('./pages/wholesale/item-edit/item-edit.module').then( m => m.ItemEditPageModule)
  },
  {
    path: 'wholesale',
    canActivate: [AngularFireAuthGuard, RoleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToWholesale,
      role: 'wholesale'
    },
    children: [
      {
        path: 'id/:id',
        loadChildren: () => import('./pages/wholesale/wholesale/wholesale.module').then( m => m.WholesalePageModule)
      },
      {
        path: 'item-detail/:id',
        loadChildren: () => import('./pages/wholesale/item-detail/item-detail.module').then( m => m.ItemDetailPageModule)
      },
      {
        path: 'checkout-cart',
        loadChildren: () => import('./pages/wholesale/checkout-cart/checkout-cart.module').then( m => m.CheckoutCartPageModule)
      },
      {
        path: 'checkout-review',
        loadChildren: () => import('./pages/wholesale/checkout-review/checkout-review.module').then( m => m.CheckoutReviewPageModule)
      },
      {
        path: 'checkout-order',
        loadChildren: () => import('./pages/wholesale/checkout-order/checkout-order.module').then( m => m.CheckoutOrderPageModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/wholesale/wholesale-search/wholesale-search.module').then( m => m.WholesaleSearchPageModule)
      },
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'wholesale-search',
    loadChildren: () => import('./pages/wholesale/wholesale-search/wholesale-search.module').then( m => m.WholesaleSearchPageModule)
  },
  {
    path: 'donate/:id',
    loadChildren: () => import('./pages/donation/donate/donate.module').then( m => m.DonatePageModule)
  },
  {
    path: 'donation/checkout',
    loadChildren: () => import('./pages/donation/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'donation/order',
    loadChildren: () => import('./pages/donation/order/order.module').then( m => m.OrderPageModule)
  },
  // {
  //   path: 'image-modal',
  //   loadChildren: () => import('./image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  // },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
