// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCxmRFJroTdhKguraaZOQlfZvGyVDusZHg",
    authDomain: "fundables-4bd84.firebaseapp.com",
    databaseURL: "https://fundables-4bd84.firebaseio.com",
    projectId: "fundables-4bd84",
    storageBucket: "fundables-4bd84.appspot.com",
    messagingSenderId: "227547615205",
    appId: "1:227547615205:web:6ccb7ef8f1972d00"
  },

  stripe_test_key: "pk_test_51GzWCnFQ1PwN5NU0yFXxLxLTLsTui0DzZ5So0PlXPGaAiwq9kqClt73Fh7XaaYI00S31WFHEP3Luj31dQhwBTq4V00VLGSDzKR",
  stripe_live_key: "pk_live_51GzWCnFQ1PwN5NU0M3xtJ5x7A3w1AcRFq1DW7J0GeOAEtjSL2g8hRZXc1Rqtk23lQt82DseAgdIZjVBRqRaRG0UL00Dp2VvctG"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
