import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UrlService } from './services/url.service';
import { filter } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
//import { Platform } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from './credentials';

const { SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    private router: Router,
    private urlService: UrlService
    
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);

      console.log(this.previousUrl);
      console.log(this.currentUrl);
    });
  }


  initializeApp() {
    SplashScreen.hide().catch(error => {
      console.error(error);
    });

    // StatusBar.hide().catch(error => {
    //   console.error(error);
    // });
    firebase.initializeApp(firebaseConfig);
  }

}
