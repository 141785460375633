import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/user/auth.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    public popoverController: PopoverController
  ) { }

  ngOnInit() {}

  async dismissPopover() {
    await this.popoverController.dismiss();
  }


  myCampaigns(): void {
    this.dismissPopover();
    this.router.navigateByUrl('campaign-list');
  }

  myProfile(): void {
    this.dismissPopover();
    this.router.navigateByUrl('profile');
  }

  createCampaign(): void {
    this.dismissPopover();
    this.router.navigateByUrl('campaign-create');
  }

  logOut(): void {
    // this.dismissPopover();
    // this.router.navigateByUrl('login');
    this.authService.logoutUser().then(
      () => {
        this.dismissPopover();
        this.router.navigateByUrl('login');
      });    
  }



}
