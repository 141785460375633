import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  customer: Observable<any>;

  public userProfileRef: firebase.firestore.CollectionReference;
  public customerRef: firebase.firestore.CollectionReference;
  public userProfile: firebase.firestore.DocumentReference;
  public newUser: firebase.User;

  constructor(
    private afAuth: AngularFireAuth, 
    private db: AngularFirestore, 
  ) { 

    this.customer = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc(`customers/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )

  }



  getUser(): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(
        user => {
          if (user) {
            resolve(user);
          } else {
            reject(null);
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  loginUser(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signupUser(firstName: string, lastName: string, email: string, password: string): Promise<any> {
    let campaigns = [];
    const datecreated = Date();
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential: firebase.auth.UserCredential) => {
        this.newUser = newUserCredential.user;
        firebase
          .firestore()
          .doc(`/userProfile/${newUserCredential.user.uid}`)
          .set({ firstName, lastName, email, campaigns, datecreated });
      })
      .catch(error => {
        console.error(error);
        throw new Error(error);
      });
  }

  signupTeam(firstName: string, lastName: string, email: string, password: string, campaignId: string): Promise<any> {
    const datecreated = Date();
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential: firebase.auth.UserCredential) => {
        firebase
          .firestore()
          .doc(`/userProfile/${newUserCredential.user.uid}`)
          .set({ firstName, lastName, email, campaigns: [campaignId], datecreated })
          .then(() => {
            firebase
              .firestore()
              .doc(`/campaigns/${campaignId}`)
              .update({ "users": firebase.firestore.FieldValue.arrayUnion(newUserCredential.user.uid) })
              .catch(error => {
                console.error(error);
                throw new Error(error);
              });

          });
      })
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/email-already-in-use') {
          console.log('The email is already in use.');
          this.getUserProfile(email).then(userSnapshot => {
            console.log(userSnapshot.docs[0].id)
            firebase
              .firestore()
              .doc(`/campaigns/${campaignId}`)
              .update({ "users": firebase.firestore.FieldValue.arrayUnion(userSnapshot.docs[0].id) })
              .then(() => {
                firebase
                  .firestore()
                  .doc(`/userProfile/${userSnapshot.docs[0].id}`)
                  .update({ "campaigns": firebase.firestore.FieldValue.arrayUnion(campaignId) })
              })
              .catch(error => {
                console.log(error);
                throw new Error(error);
              });
          });
          //alert('The email is already in use.');
        } else if (errorCode == 'auth/invalid-email') {
          console.log('This email is not valid');
        } else if (errorCode == 'auth/weak-password') {
          console.log('The password is too weak.');
        } else {
          console.log(errorMessage);
        }
        throw new Error(error);
      });
  }

  async getUserProfile(email: string): Promise<firebase.firestore.QuerySnapshot> {
    this.userProfileRef = firebase
      .firestore()
      .collection(`userProfile`);
    return this.userProfileRef.where("email", "==", email).get();
  }

  async getCustomer(
    userId: string
  ): Promise<firebase.firestore.DocumentSnapshot> {
    this.customerRef = firebase
      .firestore()
      .collection(`customer/`);
    return this.customerRef.doc(userId).get();
  }

  // getUidFromEmail(
  //   email: string
  // ): string {
  //   firebase
  //     .firestore()
  //     .collection(`userProfile`).where("email", "==", email)
  //     .get()
  //     .then(function(snapshot) {
  //       console.log(snapshot);
  //       return "Email call";
  //     });
  // }

  resetPassword(email: string): Promise<void> {
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back to this page.
      url: 'https://fundables.app/login',
      handleCodeInApp: false
    };
    console.log("Email reset " + email);
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  }

  logoutUser(): Promise<void> {
    return firebase.auth().signOut();
  }
} 